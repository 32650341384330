import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'gatsby-plugin-react-intl';
import { connect } from 'react-redux';
import { sendEvent } from '../utils/amplitude';
import ReferralLayout from '../layouts/Referral/ReferralLayout';
import { searchService } from '../services/searchService';
import SteamIDParser from '../utils/steam-id-parser';

const PageInvite = () => {
	const intl = useIntl();
	const [refId, setRefId] = useState(null);
	const [refUsername, setRefUsername] = useState(null);
	const [username, setUsername] = useState(null);
	const [userid, setUserid] = useState(null);

	useEffect(() => {
		if (typeof window !== 'undefined' && window?.location?.search) {
			const urlParams = new URLSearchParams(window.location.search);
			setRefId(urlParams.get('ref'));
		}
	}, []);

	const checkUserDataExistence = async () => {
		if (!refId || !/^\d+$/.test(Number(refId))) return;
		try {
			const steamIDParser = new SteamIDParser();
			const parsedResponse = await steamIDParser.get(Number(refId), { checkForAccountID: true });
			const accountId = parsedResponse?.accountid;
			if (accountId) {
				const userData = await searchService.getWhoIsItData(accountId);
				setUsername(userData?.accountData?.username);
				setUserid(userData?.accountData?.steamAccountId);
			}
		} catch (error) {
			console.log(error, 'error')
		}
	}

	useEffect(() => {
		checkUserDataExistence();
	}, [refId]);

	const metaImage = `/referral/meta/referral-meta-${intl.locale}.jpg`;

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<meta name="robots" content="noindex, nofollow" />
				<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
				<meta name="format-detection" content="telephone=no" />
				<meta httpEquiv="x-dns-prefetch-control" content="on" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>{intl.formatMessage({ id: 'invite.meta.title' })}</title>
				<meta name="description" content={intl.formatMessage({ id: 'invite.meta.description' })} />
				<meta property="og:title" content={intl.formatMessage({ id: 'invite.meta.title' })} />
				<meta property="og:description" content={intl.formatMessage({ id: 'invite.meta.description' })} />
				<meta property="og:image" content={metaImage} />
				<meta property="og:image:secure_url" content={metaImage} />
				<meta property="og:image:type" content="image/jpg" />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />
				<meta name="twitter:site" content="@scope_gg" />
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content={intl.formatMessage({ id: 'invite.meta.title' })} />
				<meta name="twitter:description" content={intl.formatMessage({ id: 'invite.meta.description' })} />
				<meta name="twitter:image" content={metaImage} />
				<link rel="icon" href="/favicon.ico" />
			</Helmet>
			<ReferralLayout username={username} userid={userid} />
		</>
	);
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(null, mapDispatchToProps)(PageInvite);
