import React, { useEffect, useState } from 'react';
import * as styles from './referral.module.scss';
import { authService } from '../../services/authService';
import { setLoggedIn, setUserData } from '../../state/auth/actions';
import { connect } from 'react-redux';
import { isBrowser } from '../../utils/ssr';
import { useIntl } from 'gatsby-plugin-react-intl';
import { useSelector } from 'react-redux';
import { mixPanelService } from '../../services/mixPanelService';
import Favicon from '../../assets/images/favicon.ico';
import CS2LoginModal from '../../components/modals/CS2LoginModal';
import Header from '@components/molecules/Header';
import { setLoginPopupOpen } from '@src/state/loginPopup/actions';
import LoginPopup from '@components/molecules/LoginPopup';
import ReferralsHeader from '../../components/molecules/referrals/ReferralsHeader';
import FirstScreen from '../../components/molecules/referrals/FirstScreen';
import Features from '../../components/molecules/referrals/Features';
import Clips from '../../components/molecules/referrals/Clips';
import Utility from '../../components/molecules/referrals/Utility';
import HowToGet from '../../components/molecules/referrals/HowToGet';

const ReferralLayout = ({ username, userid, setIsLoggedIn, setUserData, isLoginPopupOpen, setLoginPopupOpen }) => {
	const intl = useIntl();
	const lang = intl.locale;
	const [showModal, setShowModal] = useState(false);
	const account = useSelector((state) => state.authReducer);

	useEffect(() => {
		setTimeout(() => {
			mixPanelService.sendEvent({
				event: 'Loadpage Referral Landing',
				properties: {},
			});
		}, 1500);
	}, []);

	const onClickSignUpHandler = async (location) => {
		await mixPanelService.sendEvent({
			event: 'Refferal Page / Click Sign In Button',
			properties: {
				location
			},
		});
		const appUrl = process.env.GATSBY_APP_URL;
		if (process.env.GATSBY_ACTIVE_ENV === 'development') {
			window.location = `http://fron-398-referral.frontend.stats-staging.services/${lang !== 'en' ? lang + '/' : ''}signup/sources?referrerId=${userid}`;
		} else {
			window.location = `${appUrl}/${lang !== 'en' ? lang + '/' : ''}signup/sources?referrerId=${userid}`;
		}
	};

	return (
		<>
			<div className={styles.main}>
				<img src="/referral/BG.jpg" />
				<ReferralsHeader />
				<div className={styles.wrapper}>
					<div className={styles.container}>
						<FirstScreen
							username={username}
							onClickButton={() => onClickSignUpHandler(1)}
							isLoggedIn={account.isLoggedIn}
						/>
					</div>
					<Features />
					<div className={styles.container}>
						<Clips isLoggedIn={account.isLoggedIn} onClickButton={() => onClickSignUpHandler(3)} />
					</div>
					<Utility isLoggedIn={account.isLoggedIn} onClickButton={() => onClickSignUpHandler(4)} />
					<div className={styles.container}>
						<HowToGet />
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	isLoginPopupOpen: state.loginPopupReducer.isOpen,
});

const mapDispatchToProps = (dispatch) => ({
	setIsLoggedIn: (payload) => dispatch(setLoggedIn(payload)),
	setUserData: (payload) => dispatch(setUserData(payload)),
	setLoginPopupOpen: (payload) => dispatch(setLoginPopupOpen(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferralLayout);
