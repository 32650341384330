import React, { useState, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './utility.module.scss';
import Carousel from '@components/molecules/Carousel';

const Utility = ({ isLoggedIn, onClickButton }) => {
	const intl = useIntl();
	const lang = intl.locale;

	return (
		<section className={styles.utility}>
			<div className={styles.utility__title}>
				{intl.formatMessage({ id: 'invite.utility.title' })}
			</div>
			<div className={styles.utility__row}>
				<div className={styles.utility__column}>
					<div className={styles.utility__column__img}>
						<img src={`/referral/Utility-1-${lang}.png`} alt="" />
					</div>
					<div className={styles.utility__column__title}>
						{intl.formatMessage({ id: 'invite.utility.onetitle' })}
					</div>
				</div>
				<div className={styles.utility__column}>
					<div className={styles.utility__column__img}>
						<img src={`/referral/Utility-2-${lang}.png`} alt="" />
					</div>
					<div className={styles.utility__column__title}>
						{intl.formatMessage({ id: 'invite.utility.twotitle' })}
					</div>
				</div>
				<div className={styles.utility__column}>
					<div className={styles.utility__column__img}>
						<img src={`/referral/Utility-3-${lang}.png`} alt="" />
					</div>
					<div className={styles.utility__column__title}>
						{intl.formatMessage({ id: 'invite.utility.threetitle' })}
					</div>
				</div>
			</div>
			<div className={styles.utility__carousel}>
				<div className={styles.utility__carousel__wrapper}>
					<Carousel
						elements={[
							<div className={styles.utility__column} key="1">
								<div className={styles.utility__column__img}>
									<img src={`/referral/Utility-1-${lang}.png`} alt="" />
								</div>
								<div className={styles.utility__column__title}>
									{intl.formatMessage({ id: 'invite.utility.onetitle' })}
								</div>
							</div>,
							<div className={styles.utility__column} key="2">
								<div className={styles.utility__column__img}>
									<img src={`/referral/Utility-2-${lang}.png`} alt="" />
								</div>
								<div className={styles.utility__column__title}>
									{intl.formatMessage({ id: 'invite.utility.twotitle' })}
								</div>
							</div>,
							<div className={styles.utility__column} key="3">
								<div className={styles.utility__column__img}>
									<img src={`/referral/Utility-3-${lang}.png`} alt="" />
								</div>
								<div className={styles.utility__column__title}>
									{intl.formatMessage({ id: 'invite.utility.threetitle' })}
								</div>
							</div>,
							<div className={styles.utility__column_empty} key="4" />
						]}
						itemsPerPage={2}
						gap={16}
						containerGap={0}
						arrowLeft={undefined}
						arrowRight={undefined}
					/>
				</div>
			</div>
			<div className={styles.utility__button}>
				{!isLoggedIn ? (
					<button className={styles.referrals_button} onClick={onClickButton}>
						{intl.formatMessage({ id: 'invite.utility.button' })}
					</button>
				) : null}
			</div>
		</section>
	);
};

export default Utility;
