import { isBrowser } from '@src/utils/ssr';

export const getOperatingSystem = () => {
	if (!isBrowser) return 'Unknown';

	const userAgent = window.navigator.userAgent.toLowerCase();

	if (userAgent.includes('mac')) {
		return 'macOS';
	} else if (userAgent.includes('win')) {
		return 'Windows';
	} else if (userAgent.includes('linux')) {
		return 'Linux';
	}

	return 'Unknown';
};
