import React, { useState, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './features.module.scss';
import Carousel from '@components/molecules/Carousel';

const Features = () => {
	const intl = useIntl();
	const lang = intl.locale;

	return (
		<section className={styles.features}>
			<div className={styles.features__title}>
				{intl.formatMessage({ id: 'invite.features.title' })}
				<span>{intl.formatMessage({ id: 'invite.features.subtitle' })}</span>
			</div>
			<div
				className={styles.features__desc}
				dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'invite.features.desc' }) }}
			/>
			<div className={styles.features__list}>
				<div className={styles.features__block}>
					<div className={styles.features__wrapper}>
						<div className={styles.features__block_content}>
							<div className={styles.features__block_title}>
								{intl.formatMessage({ id: 'invite.features.list.one.title' })}
							</div>
							<img src="/referral/Features-Play.svg" />
						</div>
						<div className={styles.features__block_desc}>
							{intl.formatMessage({ id: 'invite.features.list.one.desc' })}
						</div>
					</div>
					<img src={`/referral/features-1-${lang}.jpg`} />
				</div>
				<div className={styles.features__block}>
					<div className={styles.features__wrapper}>
						<div className={styles.features__block_content}>
							<div className={styles.features__block_title}>
								{intl.formatMessage({ id: 'invite.features.list.two.title' })}
							</div>
							<img src="/referral/Features-Prematch.svg" />
						</div>
						<div className={styles.features__block_desc}>
							{intl.formatMessage({ id: 'invite.features.list.two.desc' })}
						</div>
					</div>
					<img src={`/referral/features-2-${lang}.jpg`} />
				</div>
				<div className={styles.features__block}>
					<div className={styles.features__wrapper}>
						<div className={styles.features__block_content}>
							<div className={styles.features__block_title}>
								{intl.formatMessage({ id: 'invite.features.list.three.title' })}
							</div>
							<img src="/referral/Features-Compare.svg" />
						</div>
						<div className={styles.features__block_desc}>
							{intl.formatMessage({ id: 'invite.features.list.three.desc' })}
						</div>
					</div>
					<img src={`/referral/features-3-${lang}.jpg`} />
				</div>
			</div>
			<div className={styles.features__carousel}>
				<div className={styles.features__carousel__wrapper}>
					<Carousel
						elements={[
							<div className={styles.features__block_1} key="1">
								<div className={styles.features__wrapper}>
									<div className={styles.features__block_content}>
										<div className={styles.features__block_title}>
											{intl.formatMessage({ id: 'invite.features.list.one.title' })}
										</div>
										<img src="/referral/Features-Play.svg" />
									</div>
									<div className={styles.features__block_desc}>
										{intl.formatMessage({ id: 'invite.features.list.one.desc' })}
									</div>
								</div>
								<img src={`/referral/features-1-${lang}.jpg`} />
							</div>,
							<div className={styles.features__block_2} key="2">
								<div className={styles.features__wrapper}>
									<div className={styles.features__block_content}>
										<div className={styles.features__block_title}>
											{intl.formatMessage({ id: 'invite.features.list.two.title' })}
										</div>
										<img src="/referral/Features-Prematch.svg" />
									</div>
									<div className={styles.features__block_desc}>
										{intl.formatMessage({ id: 'invite.features.list.two.desc' })}
									</div>
								</div>
								<img src={`/referral/features-2-${lang}.jpg`} />
							</div>,
							<div className={styles.features__block_3} key="3">
								<div className={styles.features__wrapper}>
									<div className={styles.features__block_content}>
										<div className={styles.features__block_title}>
											{intl.formatMessage({ id: 'invite.features.list.three.title' })}
										</div>
										<img src="/referral/Features-Compare.svg" />
									</div>
									<div className={styles.features__block_desc}>
										{intl.formatMessage({ id: 'invite.features.list.three.desc' })}
									</div>
								</div>
								<img src={`/referral/features-3-${lang}.jpg`} />
							</div>,
							<div className={styles.features__block_empty} key="4" />
						]}
						itemsPerPage={2}
						gap={16}
						containerGap={0}
						arrowLeft={undefined}
						arrowRight={undefined}
					/>
				</div>
			</div>
		</section>
	);
};

export default Features;
